var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body p-0",style:('transition: all 1s ease 0s;overflow: hidden;height:' +_vm.height / _vm.bol +'px;'),attrs:{"id":"test"}},[(_vm.isExperienceCategory)?_c('div',{staticClass:"closed",attrs:{"id":"box"}},_vm._l((20),function(index){return _c('div',{key:index,staticClass:"skeleton p-3 mb-3"})}),0):_vm._e(),_c('div',{staticClass:"item-list"},[(_vm.isDetail)?[(!_vm.isExperienceCategory)?_c('ul',{staticClass:"list-group mb-0 closed p-0",attrs:{"id":"experiencebox"}},_vm._l((_vm.serviceList),function(item,i){return _c('li',{key:i,staticClass:"list-group-item"},[_c('a',{staticClass:"text-dark",attrs:{"href":'/experiences/' + item.short_name + '/detail'}},[_vm._v(" "+_vm._s(item.experience_name)+" "),_c('span',{staticClass:"badgetext badge rounded-pill bg-secondary mb-0"},[_vm._v(_vm._s(item.count))])])])}),0):_vm._e()]:[(!_vm.isExperienceCategory)?_c('ul',{staticClass:"list-group mb-0 closed p-0",attrs:{"id":"experiencebox"}},_vm._l((_vm.serviceList),function(item,i){return _c('li',{key:i,staticClass:"list-group-item"},[(!_vm.$route.params.city && !_vm.$route.params.districts)?[_c('router-link',{staticClass:"text-dark",attrs:{"to":'/experiences/' + item.short_name}},[_vm._v(" "+_vm._s(item.experience_name)+" "),_c('span',{staticClass:"badgetext badge rounded-pill bg-secondary mb-0"},[_vm._v(_vm._s(item.count))])])]:_vm._e(),(_vm.$route.params.city && !_vm.$route.params.districts)?[_c('router-link',{staticClass:"text-dark",attrs:{"to":'/search/' + _vm.$route.params.city + '/' + item.short_name}},[_vm._v(" "+_vm._s(item.experience_name)+" "),_c('span',{staticClass:"badgetext badge rounded-pill bg-secondary mb-0"},[_vm._v(_vm._s(item.count))])])]:_vm._e(),(_vm.$route.params.city && _vm.$route.params.districts)?[_c('router-link',{staticClass:"text-dark",attrs:{"to":'/city/' +
                  _vm.$route.params.city +
                  '/' +
                  _vm.$route.params.districts +
                  '/' +
                  item.short_name}},[_vm._v(" "+_vm._s(item.experience_name)+" "),_c('span',{staticClass:"badgetext badge rounded-pill bg-secondary mb-0"},[_vm._v(_vm._s(item.count))])])]:_vm._e()],2)}),0):_vm._e()]],2)]),_c('div',{staticClass:"loadmore",on:{"click":_vm.loadmore}},[(_vm.calenderToggleBool)?_c('i',{staticClass:"fa fa-angle-down"}):_vm._e(),(!_vm.calenderToggleBool)?_c('i',{staticClass:"fa fa-angle-up"}):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_vm._v("Terapi")])])
}]

export { render, staticRenderFns }