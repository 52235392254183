<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">Terapi</h3>
    </div>
    <div
      class="card-body p-0"
      id="test"
      :style="'transition: all 1s ease 0s;overflow: hidden;height:' +height / bol +'px;'"
    >
      <div class="closed" id="box" v-if="isExperienceCategory">
        <div class="skeleton p-3 mb-3" v-for="index in 20" :key="index"></div>
      </div>
      <div class="item-list">
        <template v-if="isDetail">
          <ul
            class="list-group mb-0 closed p-0"
            id="experiencebox"
            v-if="!isExperienceCategory"
          >
            <li
              class="list-group-item"
              v-for="(item, i) in serviceList"
              :key="i"
            >
              <a
                :href="'/experiences/' + item.short_name + '/detail'"
                class="text-dark"
              >
                {{ item.experience_name }}
                <span class="badgetext badge rounded-pill bg-secondary mb-0">{{
                  item.count
                }}</span>
              </a>
            </li>
          </ul>
        </template>
        <template v-else>
          <ul
            class="list-group mb-0 closed p-0"
            id="experiencebox"
            v-if="!isExperienceCategory"
          >
            <li
              class="list-group-item"
              v-for="(item, i) in serviceList"
              :key="i"
            >
              <template v-if="!$route.params.city && !$route.params.districts">
                <router-link
                  :to="'/experiences/' + item.short_name"
                  class="text-dark"
                >
                  {{ item.experience_name }}
                  <span
                    class="badgetext badge rounded-pill bg-secondary mb-0"
                    >{{ item.count }}</span
                  >
                </router-link>
              </template>
              <template v-if="$route.params.city && !$route.params.districts">
                <router-link
                  :to="'/search/' + $route.params.city + '/' + item.short_name"
                  class="text-dark"
                >
                  {{ item.experience_name }}
                  <span
                    class="badgetext badge rounded-pill bg-secondary mb-0"
                    >{{ item.count }}</span
                  >
                </router-link>
              </template>
              <template v-if="$route.params.city && $route.params.districts">
                <router-link
                  :to="
                    '/city/' +
                    $route.params.city +
                    '/' +
                    $route.params.districts +
                    '/' +
                    item.short_name
                  "
                  class="text-dark"
                >
                  {{ item.experience_name }}
                  <span
                    class="badgetext badge rounded-pill bg-secondary mb-0"
                    >{{ item.count }}</span
                  >
                </router-link>
              </template>
            </li>
          </ul>
        </template>
      </div>
    </div>
    <div @click="loadmore" class="loadmore">
      <i v-if="calenderToggleBool" class="fa fa-angle-down"></i>
      <i v-if="!calenderToggleBool" class="fa fa-angle-up"></i>
    </div>
  </div>
</template>
<style scoped>
.activeSelect {
  box-shadow: 0px 0px 20px 0px #bb7494;
  -webkit-transition: 0.7s ease-in;
  -o-transition: 0.7s ease-in;
  transition: 0.7s ease-in;
  position: relative;
  z-index: 19;
}
.nocon {
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
}
.topmenu {
  padding: 0 !important;
  background: #eaeaea;
  margin-bottom: 10px;
  margin-left: -10px;
  margin-top: -10px;
  height: 52px;
}
.loadmore {
  text-align: center;
  font-size: 20px;
  box-shadow: 0px -16px 15px -14px #000000;
  position: inherit;
}
.loadmore:hover {
  cursor: pointer;
}
.left {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  left: -15px;
  top: 60px;
  z-index: 9;
  padding: 11px !important;
  background: white;
  box-shadow: -10px 0px 10px -5px #cfcfcf;
}
.right {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  right: -15px;
  top: 60px;
  z-index: 9;
  padding: 11px !important;
  background: white;
  box-shadow: 10px 0px 10px -5px #cfcfcf;
}
.dateList {
  display: inline-flex;
  position: absolute;
  left: 0;
  top: 0;
}
.dateList div {
  width: 88px;
  text-align: center;
  padding: 10px;
}
.dateList li {
  padding: 5px;
  margin: 5px;
  color: white;
  border-radius: 10px;
}
.dateList li a {
  color: white;
  cursor: pointer;
}
.dateList li a:hover {
  color: white !important;
  cursor: pointer;
}
</style>
<script>
export default {
  props: ["isDetail"],
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
  },
  data() {
    return {
      height: 0,
      bol: 12,
      serviceList: [],
      isExperienceCategory: true,
      calenderToggleBool: true,
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    "$route.params.city": function () {this.load();},
    "$route.params.districts": function () {this.load();},
  },
  methods: {
    load() {
      let serviceInfo = {};
      if (this.$route.params.city || this.$route.params.districts) {
        serviceInfo = {
          city: this.$route.params.city,
          districts: this.$route.params.districts,
        };
      }
      this.$store.dispatch("serviceList", serviceInfo).then((value) => {
        this.serviceList = value;
        this.isExperienceCategory = false;
        setTimeout(() => {
          this.height = document.getElementById("experiencebox").offsetHeight;
        }, 1000);
      });
    },
    loadmore() {
      if (this.bol == 1) {
        this.calenderToggleBool = true;
        this.bol = 6;
      } else {
        this.calenderToggleBool = false;
        this.bol = 1;
      }
    },
  },
};
</script>